import React from 'react'
import { graphql } from 'gatsby'
import Pagina from '../components/Pagina'
import CardService from '../components/CardService/index'
import BackgroundImg from 'gatsby-background-image'
import { convertToBgImage } from 'gbimage-bridge'
import { getImage } from 'gatsby-plugin-image'
import { useIntl, Link } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// CSS, SCSS
// import 'swiper/css/swiper.css'
import './styles/nossas-solucoes.scss'

// Assets
import icon1 from '../images/icon/home/Home_01.svg'
import icon2 from '../images/icon/home/Home_02.svg'
import icon3 from '../images/icon/home/Home_03.svg'

const ServicosEProdutos = ({ data }) => {
  const intl = useIntl()

  return <>
    <Pagina pagina={data.pagina} />
    {/* <BannerLwart
      desktop={data.paralax.childImageSharp.gatsbyImageData}
      mobile={data.paralaxMobile.childImageSharp.gatsbyImageData}
      titulo='Soluções ecoeficientes que preservam o planeta'
      texto="Conheça nossos serviços e produtos."
      className="paralaxImage"
    /> */}
    <Paralax paralax={data.paralax.childImageSharp.gatsbyImageData} paralaxMobile={data.paralaxMobile.childImageSharp.gatsbyImageData} />

    <section className="full m-0 p-0">
      <div className="gradient-blue-to-green"></div>
      <section className="bg-lwart-lightblue2 full m-0 p-0 sectionServicos">
        <div className="container">
          <div className="row p-lg-5 px-4">
            <div className="col-md-4 mb-lg-0 mb-lg-5 cardServicosProdutos px-0 p-lg-3">
              <Link to="/coleta-de-oleo-lubrificante-usado/" className="text-decoration-none">
                <CardService icon={icon1} cardImage={data.card1.childImageSharp.gatsbyImageData} bgColor={'bg-lwart-lightblue2'}
                  title={parse(intl.formatMessage({ id: 'paginas.nossas_solucoes.servicos_produtos.coleta.title' }))}
                  text={parse(intl.formatMessage({ id: 'paginas.nossas_solucoes.servicos_produtos.coleta.text' }))}>
                </CardService>
              </Link>
            </div>
            <div className="col-md-4 mb-lg-0 mb-lg-5 cardServicosProdutos px-0 p-lg-3">
              <Link to="/oleos-de-alta-performance/" className="text-decoration-none">
                <CardService icon={icon2} cardImage={data.card2.childImageSharp.gatsbyImageData} bgColor={'bg-lwart-darkblue'}
                  title={parse(intl.formatMessage({ id: 'paginas.nossas_solucoes.servicos_produtos.oleo.title' }))}
                  text={parse(intl.formatMessage({ id: 'paginas.nossas_solucoes.servicos_produtos.oleo.text' }))}>
                </CardService>
              </Link>
            </div>
            <div className="col-md-4 mb-lg-0 mb-lg-5 cardServicosProdutos px-0 p-lg-3">
              <Link to="/gestao-de-residuos/" className="text-decoration-none">
                <CardService icon={icon3} cardImage={data.card3.childImageSharp.gatsbyImageData} bgColor={'bg-lwart-green'}
                  title={parse(intl.formatMessage({ id: 'paginas.nossas_solucoes.servicos_produtos.gestao.title' }))}
                  text={parse(intl.formatMessage({ id: 'paginas.nossas_solucoes.servicos_produtos.gestao.text' }))}>
                </CardService>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </section>
  </>
}
const Paralax = ({ paralax, paralaxMobile }) => {
  const image = getImage(paralax)
  const bgImage = convertToBgImage(image)
  const imageMobile = getImage(paralaxMobile)
  const bgImageMobile = convertToBgImage(imageMobile)
  const intl = useIntl()

  return <>
    <div>
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage d-lg-block d-none"
      >
        <div className="container h-100 d-flex justify-content-end ">
          <div className=" h-100 d-flex align-items-center">
            <div className="w-100">
              <h1 className="pb-4 text-white">{parse(intl.formatMessage({ id: 'paginas.nossas_solucoes.paralax.title' }))}</h1>
              <p className="line-height-2 text-white">
                {parse(intl.formatMessage({ id: 'paginas.nossas_solucoes.paralax.p' }))}
              </p>
            </div>
          </div>
        </div>
      </BackgroundImg>
      <BackgroundImg
        {...bgImageMobile}
        Tag="div"
        className="paralaxImage d-lg-none d-block"
      >
        <div className="container h-100 d-flex justify-content-center ">
          <div className=" h-100 d-flex align-items-center">
            <div className="w-100">
              <h1 className="pb-4 text-white">{parse(intl.formatMessage({ id: 'paginas.nossas_solucoes.paralax.title' }))}</h1>
              <p className="line-height-2 text-white">
                {parse(intl.formatMessage({ id: 'paginas.nossas_solucoes.paralax.p' }))}
              </p>
            </div>
          </div>
        </div>
      </BackgroundImg>
    </div>
  </>
}

export default ServicosEProdutos

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/nossas-solucoes/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  paralax: file(
    relativePath: {eq: "nossas-solucoes/shutterstock_760733977a.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralaxMobile: file(
    relativePath: {eq: "nossas-solucoes/shutterstock_760733977a.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  card1: file(relativePath: {eq: "home/cards/card-1.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  card2: file(relativePath: {eq: "home/cards/card-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  card3: file(relativePath: {eq: "home/cards/card-3.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
